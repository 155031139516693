'use client';

import Script from 'next/script';

export type UserDetailsForPendo = {
  accountName?: string;
  userLoginName?: string;
};

function inititalisePendo(userDetails: UserDetailsForPendo, env: string, ipAddress: string): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.pendo) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: userDetails?.userLoginName ?? null,
        environment: env,
        screenResolution: window.screen ? `${window.screen.width}*${window.screen.height}` : null,
        ipAddress,
        source: 'sriracha2.0'
      },
      account: {
        id: userDetails?.accountName ?? null,
        source: 'sriracha2.0'
      }
    });
  }
}

type Props = {
  pendoUrl: string;
  userDetails: UserDetailsForPendo;
  env: string;
  ipAddress: string;
};

export default function Pendo({ pendoUrl, userDetails, env, ipAddress }: Props): JSX.Element {
  return (
    <Script
      id="pendo"
      async
      src={pendoUrl}
      strategy="lazyOnload"
      onLoad={() => {
        let w;
        let x;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const o = (typeof window !== 'undefined' && window.pendo) || {};
        o._q = o._q || [];
        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {
          // eslint-disable-next-line wrap-iife,func-names
          (function (m) {
            o[m] =
              o[m] ||
              // eslint-disable-next-line func-names
              function () {
                // eslint-disable-next-line prefer-rest-params
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        }

        inititalisePendo(userDetails, env, ipAddress);
      }}
    />
  );
}

'use client';

import { createContext, ReactNode, useMemo, useState } from 'react';

export const SiteCatalystContext = createContext({
  isSiteCatLoaded: false,
  setIsSiteCatLoaded: _value => {}
});

export default function SiteCatalystProvider({ children }: { children: ReactNode }): JSX.Element {
  const [isSiteCatLoaded, setIsSiteCatLoaded] = useState(false);
  const siteCatContext = useMemo(() => ({ isSiteCatLoaded, setIsSiteCatLoaded }), [isSiteCatLoaded]);

  return <SiteCatalystContext.Provider value={siteCatContext}>{children}</SiteCatalystContext.Provider>;
}

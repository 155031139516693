import { type Dispatch, type SetStateAction, useContext } from 'react';
import { SiteCatalystContext } from '@/providers/SiteCatalystProvider';

export type SiteCatalystProvider = {
  isSiteCatLoaded: boolean;
  setIsSiteCatLoaded: Dispatch<SetStateAction<boolean>>;
};

/**
 * hook that tells if site catalyst script has been loaded, so we are good to log facts
 */
export default function useSiteCatalyst(): SiteCatalystProvider {
  return useContext<SiteCatalystProvider>(SiteCatalystContext);
}

import React, { type ReactNode } from 'react';

/**
 * Context which stores app features
 */
export const AppFeaturesContext = React.createContext<Record<string, boolean>>({});

interface Props {
  children: ReactNode;
  value: Record<string, boolean>;
}

/**
 * Provider which can be used to initialize the app features context
 */
export function AppFeatureProvider({ children, value }: Props): JSX.Element {
  return <AppFeaturesContext.Provider value={value}>{children}</AppFeaturesContext.Provider>;
}

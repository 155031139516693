'use client';

import { useEffect } from 'react';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { usePathname, useSearchParams } from 'next/navigation';
import { VIEW, queryStringConstants } from '@/constants/queryConstants';
import { useRouter } from '@/lib/router-events/patch-router/router';
import { prepareUrl } from '@/utils/urlHelper';
import { CARINA_XL } from '@/providers/BreakPointProvider';

// small component that fall backs to map view for mobile and tablet if the view is of FULL MAP
export default function MobileDesktopNavigation() {
  const { screenWidth } = useBreakpoints();
  const searchParams = useSearchParams();
  const path = usePathname();
  const router = useRouter();
  const isFullMap = searchParams.get(queryStringConstants.VIEW) === VIEW.FULL_MAP;
  const isViewQueryParamPresent = searchParams.get(queryStringConstants.VIEW) === VIEW.MAP;

  useEffect(() => {
    // usage of useBreakpoints only because this is not important for SEO
    if (screenWidth && isFullMap && screenWidth < CARINA_XL) {
      router.replace(prepareUrl(path, searchParams, ...[{ key: queryStringConstants.VIEW, value: VIEW.MAP }]));
    }
    // removes view === Map query param from url in case of small/large desktop
    if (screenWidth && isViewQueryParamPresent && screenWidth > CARINA_XL) {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete(queryStringConstants.VIEW);
      router.replace(prepareUrl(path, newParams));
    }
  }, [isFullMap, isViewQueryParamPresent, path, router, screenWidth, searchParams]);

  return <></>;
}
